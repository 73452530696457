<template>
    <div style="position: relative">
        <div class="dropdown-heading" @click="open = ! open">
            <span v-if="hasTime" class="is-time">
                <display-time :time="time" :timezone="timezone"/>
                <img src="https://s3.us-east-2.amazonaws.com/static.videosuite.io/img/noun_Arrow_967604+(1).png" class="caret selected-caret" />
            </span>
            <span v-else>
                Available Dates <img src="https://s3.us-east-2.amazonaws.com/static.videosuite.io/img/noun_Arrow_967604+(1).png" class="caret" />
            </span>
        </div>
        <transition name="component-fade" mode="out-in">
                <ul v-if="open" class="dropdown-content">
                    <li v-for="time in times" @click="selectTime(time)">
                        <display-time :time="time" :timezone="timezone"/>
                    </li>
                </ul>
        </transition>

        <div class="form-group error-alert" v-if="errors.getSchedule">
            <p class="error-text">
                We're unable to get the webinar schedule at this time, please contact our support team on <a href="mailto:support@videosuite.io">support@videosuite.io</a>
            </p>
        </div>

        <!-- Browser cache hack for the image-->
        <img src="https://s3.us-east-2.amazonaws.com/static.videosuite.io/img/noun_monthly+calendar_972012.png"  style="display: none"/>
    </div>
</template>
<script>
    import moment from "moment-timezone";
    import axios from 'axios';
    import DisplayTime from './DisplayTime';
    import mobile from 'is-mobile';

    export default {
      name: 'WebinarSchedule',
      data(){
        return {
          times:[],
          open: false,
          errors: {
            getSchedule: false
          }
        }
      },
      components:{
        DisplayTime
      },
      mounted(){
        this.getWebinarTimesList();
      },
      props:['time', 'timezone', 'gmt'],
      computed:{
        hasTime(){
          if(this.time){
            return (Object.keys(this.time).length)
          }
        },
        is_mobile(){
          return mobile()
        },
        params(){
          return {
            timezone: this.gmt,
            mobile: this.is_mobile
          }
        }
      },
      methods:{
        async getWebinarTimesList(){
          try {
            const req = await axios.get(window.apiRoot + '/getSchedule', {params: this.params});
            this.times= req.data;
          }catch(err) {
            console.log(err);
            this.errors.getSchedule = true;
          }
        },
        selectTime(time){
          this.open = false;
          this.$emit('selectTime', time);
        }
      }
    }
</script>
<style scoped >
    ul li {
        list-style: none;
        cursor: pointer;
        padding-left: 10px;
        padding-right: 30px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 16px;
    }
    ul {
        position: absolute;
        max-width: 100%;
        background-color: white;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: 4px;
        box-shadow: 0 6px 12px rgba(0,0,0,.175);
        margin-top:-10px;
        padding: 0;
        left:  0;
        right: 0;
    }
    li:hover {
        background-color: #f4f4f4;
    }

    .dropdown-heading {
        cursor: pointer;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 15px;
    }
    .caret {
        float: right;
        max-width: 10px;
        margin-right: 5px;
        margin-top: 4px;
    }
    @media (max-width: 600px) {
        .selected-caret {
            display: none;
        }

        ul li, .is-time {
            font-size: 14px;
        }
    }
</style>