<template>
    <div>
        <div class="form-group">
            <input v-model="name" placeholder="First Name" class="form-input" :class="[(errors.name) ? 'has-error' : '']" @keyup.enter="submit"/>
            <small v-if="errors.name" class="error-text">required</small>
        </div>
        <div class="form-group">
            <input v-model="email" placeholder="Email" class="form-input" :class="[(errors.email) ? 'has-error' : '', (errors.invalidEmail) ? 'has-error' : '']" @keyup.enter="submit"/>
            <small v-if="errors.email" class="error-text">required</small>
            <small v-if="errors.invalidEmail" class="error-text">Invalid Email</small>
        </div>
        <div class="form-group error-alert" v-if="errors.register">
            <p class="error-text">We was unable to register you for this webinar, please contact our support team on <a href="mailto:support@videosuite.io">support@videosuite.io</a></p>
        </div>
        <div class="form-group">
            <button @click="submit" class="btn ev-btn">
                <img v-if="submitting" src="https://s3.us-east-2.amazonaws.com/static.videosuite.io/img/oval.svg" class="loader"/>
                <span v-else>YES! RESERVE MY SEAT!</span>
            </button>
            <small class="info-text"><img src="https://s3.us-east-2.amazonaws.com/static.videosuite.io/img/noun_Lock_640123+(1).png" class="lock"/>&nbsp;Your Information Is 100% Secure</small>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import EmailValidator from 'email-validator';

    export default {
      name:'WebinarSignup',
      props:['time', 'timezone', 'gmt'],
      data(){
        return {
          email: '',
          name: '',
          submitting: false,
          errors:{
            email: false,
            name: false,
            register: false,
            invalidEmail: false
          }
        }
      },
      computed:{
        params(){
          return {
            'first_name' : this.name,
            'email' : this.email,
            'schedule' : this.time.schedule,
            'timezone' : this.gmt
          }
        }
      },
      methods:{
        async submit(){
          this.clearErrors();

          if(! this.email) this.errors.email = true;
          if(! this.name)  this.errors.name = true;
          if(this.errors.email || this.errors.name) return;

          // Validate the email
           if(! EmailValidator.validate(this.email) ) {
             this.errors.invalidEmail = true;
             return;
           }

            this.submitting = true;

            try {
              const req = await axios.get(window.apiRoot + '/newSignup', {params: this.params});
              window.location.href = `http://tracking.videosuite.io/lead?email=${this.email}&url=${req.data.user.thank_you_url}`;
            }catch(err){
                console.log(err);
                this.errors.register = true;
                this.submitting = false;
            }
        },
        clearErrors(){
          this.errors.name = false;
          this.errors.email = false;
          this.errors.register = false;
        }
      }
    }
</script>
<style>
    .btn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
        background-color: rgb(18, 133, 221);
        border-radius: 0;
        border-color: #4cae4c;
        color: white;
        font-size: 35px;
        color: #fff;
        padding: 10px 0px;
        width:100%;
    }

    .ev-btn:hover {
        color: white !important;
    }

    @media (max-width: 600px){
        .btn {
            font-size: 17px;
        }
    }

    .form-group {
        margin-bottom: 15px;
    }

    input {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        font-size: 16px;
    }
    .has-error {
        border-color:  #dc3545;
    }
    .lock {
        max-width: 10px;
    }
    .info-text {
        margin-top: 10px;
        display: block;
        text-align: center;
    }
    .error-text {
        color: #dc3545;
    }
    .loader {
        width: 30px !important;
        max-width: 30px !important;
    }
</style>