<template>
    <transition name="modal">
        <div v-if="open" class="modal-mask" >
                <div class="modal-container" v-on-clickaway="away">

                    <div class="modal-header">
                        <img src="https://s3.us-east-2.amazonaws.com/static.videosuite.io/img/noun_Close_659811.png" class="close-icon" @click="away" />
                        <transition name="modal">
                            <progress-bar :progress="progress" v-if="progress > 0" />
                        </transition>
                    </div>

                    <div class="modal-body">
                        <h2>{{title}}</h2>
                        <WebinarSchedule @selectTime="selectTime"  :time="time" :timezone="timezone" :gmt="timezoneGMT"/>
                        <transition name="component-fade" mode="out-in">
                            <WebinarSignup  v-if="progress === 90"  :time="time" :timezone="timezone" :gmt="timezoneGMT"/>
                        </transition>
                    </div>

                    <div class="clearfix" ></div>
                </div>
        </div>
    </transition>
</template>
<script>
  import ProgressBar from './ProgressBar';
  import WebinarSignup from './WebinarSignup';
  import WebinarSchedule from './WebinarSchedule';
  import { mixin as clickaway } from 'vue-clickaway';
  import moment from "moment-timezone";
  import forEach from 'lodash/forEach';

    export default {
      name:'Modal',
      components:{
        WebinarSignup,
        WebinarSchedule,
        ProgressBar
      },
      mixins: [ clickaway ],
      mounted(){
        const int = setInterval(()=>{
          let btns = document.getElementsByClassName('everwebinar-signup');
          if(btns.length) {
            clearInterval(int);
            forEach(btns, (btn)=>{
              this.registerCickEvent(btn);
            })
          }
        }, 500)
      },
      computed:{
        title(){
          if(this.progress > 75 ){
            return "Enter your Name and Email Address below to register for the webinar and secure your seat.";
          }
          return "Select a date and time for your webinar";
        },
        timezone(){
          return moment.tz.guess();
        },
        timezoneGMT(){
          let offsetHours = moment.tz.zone( this.timezone ).offsets[1] / 60;
          return "GMT" + ((offsetHours < 0) ? "+" + Math.abs(offsetHours) : "-" + offsetHours);
        },
      },
      data(){
        return {
          open: false,
          progress:  0,
          time: {}
        }
      },
      methods:{
        toggleModal(){
            this.open = ! this.open;
        },
        selectTime(time){
          this.time = time;
          this.progress = ( Object.keys(time).length ) ? 90 : 75;
        },
        away(){
          this.open = false;
          this.time = {};
        },
        registerCickEvent(btn){
          if(! window.apiRoot) {
            window.apiRoot = btn.getAttribute('data-api-root');
          }

          btn.onclick = ()=>{
            this.progress = 75;
            this.toggleModal();
          }
        }
      }
    }
</script>
<style>
    * {
        box-sizing: border-box;
    }
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-container {
        width: 600px;
        max-width: 100%;
        margin: 30px auto;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    @media(max-width: 600px){
        .modal-container {
            width: 300px;
        }

        h2 {
            font-size: 18px;
            text-align: center;
        }
    }

    .modal-header {
        border-bottom: 1px solid #ccc;
        padding: 20px 30px;
        float: left;
        width: 100%;
        position: relative;
    }

    h2 {
        margin-top: 0;
    }

    .modal-body {
        margin: 20px 0;
        padding: 20px 30px;
        float: left;
        width: 100%;
        min-height: 300px;
    }

    .modal-footer {
        float: left;
        width: 100%;
    }

    .modal-default-button {
        float: right;
    }

    .clearfix::after {
        content: "";
        clear: both;
        display: table;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    .close-icon {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 11px;
        cursor: pointer;
    }
</style>