import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false;
Vue.config.devtools = true;

const int = setInterval(()=>{
  let test = document.getElementById('everwebinar-signip-modal');
  if(test) {
    clearInterval(int);
    window.Vue = new Vue({
      render: h => h(App)
    }).$mount('#everwebinar-signip-modal');
  }
}, 500);

