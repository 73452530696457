<template>
    <div class="progress-wrapper">
        <div class="progress-inner" :style="{width: progress + '%'}">
            {{progress}}% Complete
        </div>
    </div>
</template>
<script>
    export default  {
      name: 'ProgressBar',
      props:['progress']
    }
</script>
<style>
    .progress-wrapper {
        width: 100%;
        float: left;
        background: gainsboro;
    }
    .progress-inner {
        float: left;
        height: 100%;
        font-size: 12px;
        line-height: 20px;
        color: #fff;
        text-align: center;
        -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
        box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
        -webkit-transition: width .6s ease;
        -o-transition: width .6s ease;
        transition: width .6s ease;
        background-color: #5bc0de;
    }
</style>