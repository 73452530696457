<template>
    <modal/>
</template>

<script>
import Modal from './components/Modal';

export default {
  name: 'app',
  components: {
    Modal
  },
}
</script>

 <style>
</style>
