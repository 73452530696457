<template>
    <span>
        <img src="https://s3.us-east-2.amazonaws.com/static.videosuite.io/img/noun_monthly+calendar_972012.png" />
        {{time.date}}
        <small>{{timezone}}</small>
    </span>
</template>
<script>
    export default  {
      name: 'DisplayTime',
      props:['time', 'timezone']
    }
</script>
<style>
    img {
        max-width: 18px;
    }
    small {
        opacity: 0.7;
    }
</style>